import React, { useState, useEffect } from 'react'
import { graphql, Link } from "gatsby"
import { Camera } from "react-feather"
import { Helmet } from "react-helmet"
import Img from "gatsby-image"
import { motion, useViewportScroll, useSpring, useTransform, useMotionValue } from "framer-motion"
import { VictoryPie } from "victory"
import Loadable from "@loadable/component"

import Demo from "../components/Demo"
import DemoTable from "../components/DemoTable"
import FeatureTile from "../components/FeatureTile"
import FeatureBlock from "../components/FeatureBlock"
import Layout from "../components/layouts/Layout"
import FAQ from "../components/FAQ"
import Button from "../components/Button"
import SwitchNav from "../components/SwitchNav"
import DemoStyles from "../components/Demo.module.scss"

const PlantModel = Loadable(() => import("../components/PlantModel"), {
  fallback: <div style={{height:18+'rem', paddingTop: 0+'px'}}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" style={{margin: 'auto auto', height: 50+'px'}}>
      <path opacity=".5" d="M16 0 A16 16 0 0 0 16 32 A16 16 0 0 0 16 0 M16 4 A12 12 0 0 1 16 28 A12 12 0 0 1 16 4"/>
      <path style={{fill: '#333333'}} d="M16 0 A16 16 0 0 1 32 16 L28 16 A12 12 0 0 0 16 4z">
        <animateTransform attributeName="transform" type="rotate" from="0 16 16" to="360 16 16" dur="0.8s" repeatCount="indefinite" />
      </path>
    </svg>
  </div>
})

const VideoDemo = ({ children, data }) => {
  return (
  <>
    <>{children}</>
    <motion.div
      animate={{ opacity: 1 }}
      transition={{ ease: "easeIn", duration: 0.5 }}
      className="bg-black overflow-hidden" 
      style={{
          borderRadius: 16+'px',
          height: 18+'rem'
      }}>
        {/* <video 
          autoplay="true" 
          muted="true" 
          loop="true" 
          playsinline="true"
          style={{
              borderRadius: 14+'px'
          }}>
          <source src="https://api.counterset.com/f/e280d69d-9fcc-438e-8ffb-c8565984439d" type="video/mp4" />
        </video> */}
        {/* <PlantModel /> */}
        
        <Img fluid={data.wine.fluid}></Img>
      </motion.div>
      <div className="pt-4 pb-1">
        <span style={{fontSize: 20+'px', marginLeft:8+'px'}}>Contre-Ensemble GSM</span>
        <div className={`${DemoStyles.verify}`}></div>
      </div>
      <span style={{fontSize: 16+'px', marginLeft:8+'px'}}>Tasting Notes</span>
      <div className="pb-5 text-sm" style={{marginLeft:8+'px'}}>
        Big, Textural, Black Cherries and Plums.
      </div>
      <span style={{fontSize: 16+'px', marginLeft:8+'px'}}>Blend</span>
      <VictoryPie
        data={[
          { x: 1, y: 70, label: "Grenache" },
          { x: 2, y: 20, label: "Syrah" },
          { x: 2, y: 10, label: "Mourvèdre" },
        ]}
        colorScale="qualitative"
    />
  </>
  )
}

const AuthDemo = ({ }) => {
  return (
  
  <>
  <motion.div
      animate={{ opacity: 1 }}
      transition={{ ease: "easeIn", duration: 0.2 }}
    >
  <div style={{ position: 'fixed',
                left: 0,
                top: 0,
                width: '100%',
                height: '100%',
                background: 'rgba(0, 0, 0, 0.2)',
                zIndex: 999,
                opacity: 1}} >
      <div style={{top: '158px', 
                  left: '30px',
                  width: '300px',
                  height: '315px',
                  position: 'relative',
                  overflow: 'hidden',
                  boxSizing: 'border-box',
                  backgroundColor: 'white',
                  textAlign: '',
                  borderRadius: '16px',
                  boxShadow: '0 20px 60px -2px rgba(27, 33, 58, 0.4)',
                  padding: 0}} >
          <div style={{fontWeight: 600, fontSize: '22px', padding: '18px', textAlign:'center'}}>Contre-Ensemble Pty Ltd.</div>
          <div style={{backgroundColor: '#0b2', padding: '12px 12px 12px 18px', color: '#fff'}}>
            <div style={{textAlign:'center'}}>
              <span class="verifiedtitle">
                Verified Seller
              </span>
            </div></div> 
        <div style={{padding: '12px 12px 12px 18px'}}>
          <div ><div style={{fontWeight: 600, paddingTop: '6px'}}>
          Office Address
        </div> <div class="content">
          191 Elk City Road
          <br />
          Indianapolis, Indiana 46205
        </div></div> <div ><div style={{fontWeight: 600, paddingTop: '12px'}}>
          Brands
        </div> <div class="content">
          Contre-Ensemble GSM
        </div></div></div>

      </div>
  </div>
  </motion.div>
  </>
  )
}

const LabDemo = ({ }) => {
  return (
  <>
      <div className="pt-4 pb-1">
        <span style={{fontSize: 20+'px', marginLeft:8+'px'}}>Contre-Ensemble GSM</span>
        <div className={`${DemoStyles.verify}`}></div>
      </div>
      <div className="pb-5 text-sm" style={{marginLeft:8+'px'}}>
          Blend of Grenach, Syrah, and Mourvèdre.
      </div>
      <span style={{fontSize: 16+'px', marginLeft:8+'px'}}>Test Results</span>
      <DemoTable items={[
        {
          title: "Pesticides",
          content: "Not Detected"
        },
        {
          title: "Heavy Metals",
          content: "Not Detected"
        },
        {
          title: "Pack Date",
          content: "06/07/2019"
        },
        {
          title: "Total ABV",
          content: "15.5%"
        },
        {
          title: "Batch Number",
          content: 'B12343344'
        },
        {
          title: "Batch Size",
          content: '4500 units'
        },
      ]} />

      <div style={{marginTop: '24px'}}>
      <span style={{fontSize: 16+'px', marginTop: '18px', marginLeft:8+'px'}}>Certificate of Analysis</span>
      </div>
    
      <a href="https://api.counterset.com/f/ae7e7a41-5307-4883-b2b7-05a2511b3af0"
        target="_blank" className={`${DemoStyles.download}`}>
        <div style={{                  
                  }} >
      
        Download File
      </div>
        </a>

  </>
  )
}

const Header = ({ translateY, data }) => {
  const [selectedItem, setSelectedItem] = useState(0);
  return (<>
  <div className={`bg-gray-100`}>
    <div className="w-full max-w-screen-xl mx-auto px-6">
      <div className="flex flex-wrap flex-row">
        <div className="w-full flex sm:w-1/2">
          <div className="pt-12 text-left">
            <h1 className="text-5xl text-black" style={{maxWidth: 400+'px', lineHeight: 60+'px', marginBottom: 30}}>Save space for the stuff that matters.</h1>
            <p className="text-lg text-gray-800 font-normal max-w-lg">
              CounterSet makes it simple to tell your brand story, enhance customer retention, and drive customers into your sales funnel.
            </p>

            <div className="pt-12 pb-12">
              <a style={{"cursor": "pointer"}} href="mailto:will@counterset.com" className="items-center btn btn-black">Request a demo</a>
            </div>
          </div>
        </div>
        <motion.div 
          className="hidden sm:flex sm:w-1/2 justify-end relative pt-6"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ ease: "easeIn", duration: 0.5 }}
          style={{ translateY: translateY }}
        >
          <Demo>
            {selectedItem == 0 && <VideoDemo data={data}></VideoDemo>}
            {selectedItem == 1 && <VideoDemo data={data}><AuthDemo></AuthDemo></VideoDemo>}
            {selectedItem == 2 && <LabDemo></LabDemo>}
          </Demo>
        </motion.div>
      </div>
    </div>
  </div>
  <div className="w-full max-w-screen-xl mx-auto px-6 pt-12">
    <div className="flex flex-wrap flex-row">
      <div className="w-full flex flex-col sm:w-1/2">
        <SwitchNav
          selectedCallback={(i) => setSelectedItem(i)}
          items={[
            {
              title: 'Show your stuff',
              description: 'Take advantage of our custom built website creation engine without needing to write a single line of code.'
            },
            {
              title: 'Prove authenticity',
              description: 'Prove to your customers that they are buying an authentic product with unique item level verification.'
            },
            {
              title: 'Collect Analytics',
              description: 'Gain unique insights into customer desires and purchasing patterns.'
            },
          ]}
        ></SwitchNav>
      </div>
    </div>
  </div>
</>)}

export default ({data}) => {
  const { scrollYProgress, scrollY } = useViewportScroll()
  let scrollHead = useSpring(useTransform(scrollY, v => {
    return v < 350 ? v : 350
  }), { damping: 10, stiffness:20 })

  return (
  <Layout header={<Header translateY={scrollHead} data={data}></Header>}>
    <Helmet>
      <title>Beer, Wine, and Spirits QR Codes</title>
      <meta name="description" content="CounterSet makes it easy to tell your brand story" />
      <meta property="og:description" content="CounterSet makes it easy to tell your brand story" />
    </Helmet>
    <div className="flex flex-row flex-wrap my-4 mt-16">
    </div>
    <h2 className="font-bold text-4xl mt-8 mb-8">
      Tell your story.
    </h2>
    <FeatureBlock
      title="Share what you're about"
      img={(<a target="_blank" href="https://counterset.com/p/5iU1E4uSTPWvPIHB-bUZHw"><Img fluid={data.qr.fluid}></Img></a>
      )}
    >
      <p className="mb-4">
        There's never enough space on the label to tell a customer everything you want to tell them. 
        Using our codeless website creation engine, you can enhance the post purchase experience without compromising your package design.
      </p>
      <p className="mb-4">
        You can:
      </p>
      <ul className="tick-list">
        <li>Share tasting notes</li>
        <li>Attatch Product information</li>
        <li>Funnel customers to your web store</li>
        <li>Promote your tasting venue</li>
      </ul>
    </FeatureBlock>

    <FeatureBlock
      title="Scale to thousands of SKUs effortlessly"
      img={(<Img fluid={data.batch.fluid}></Img>)}
      reverse
    >
      <p className="mb-4">
        CounterSet Batch functionality lets you create millions of unique codes with just a few clicks. Use unique codes to identify counterfeiting and gray market distribution, with minimal changes to your supply chain.
      </p>
      <p className="mb-4">
        Attach and change supply chain information for each individual code or batch. Prove to your customers and to regulators, that your product is safe and authentic.
      </p>
    </FeatureBlock>

    <FeatureBlock
      title="Do other stuff too!"
      img={(<Img fluid={data.batch.fluid}></Img>)}
    >
      <p className="mb-4">
        Got a venue? CounterSet also does all that other cool stuff that other dynamic QR providers do! You can use it for stuff like:
      </p>
      <ul className="tick-list">
        <li>Contact free menus</li>
        <li>Promotional Events and Competitions</li>
        <li>Notes for tasting flights</li>
      </ul>
    </FeatureBlock>
    
  </Layout>
)}

export const query = graphql`
query bwsFeatureImages {
  qr: imageSharp(fluid: {originalName: {eq: "banner.jpg"} }) {
    fluid {
      ...GatsbyImageSharpFluid
    }
  }
  wine: imageSharp(fluid: {originalName: {eq: "wine-logo.png"} }) {
    fluid {
      ...GatsbyImageSharpFluid
    }
  }
  identify: imageSharp(fluid: {originalName: {eq: "screen-identify.jpg"} }) {
    fluid {
      ...GatsbyImageSharpFluid
    }
  }
  batch: imageSharp(fluid: {originalName: {eq: "screen-batch.jpg"} }) {
    fluid {
      ...GatsbyImageSharpFluid
    }
  }
  template: imageSharp(fluid: {originalName: {eq: "screen-template.jpg"} }) {
    fluid {
      ...GatsbyImageSharpFluid
    }
  }
}
`